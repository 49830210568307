.topFilterWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: var(--bgc);
    padding: 16px 8px;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
    gap:24px;
}

.actionButton {
    display: flex;
    justify-content: flex-end;
    max-width: 250px;
}

.dataTableMain {
    background-color: var(--bgc);
}