.modalContentWrapper {
    width: 400px;
    background-color: var(--bgc);
    position: absolute;
    top: 50%;
    left: 30%;
    right: 0;
    margin: auto;
    padding: 24px;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    max-width: 600px;
    max-height: 600px;
    overflow-y: scroll;
  }
  
  .closeButtonIcon {
    position: absolute;
    right: -21px;
    top: -21px;
  }

  .modalTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    color: var(--primaryColor);
    font-size: 20px;
    position: relative;
  }